(function() {

    "use strict";

    const tooltip = (() => {

        const tooltipSelector = '.tooltip',
            options = {
                position: 'top',
                //animation: 'shift', // available options: 'shift' 'perspective' 'fade' 'scale'
                duration: 350,
                arrow: true,
                size: 'big',
                arrowSize: 'big',
                theme: 'pharmpedia'
            };

        const init = () => {
            new Tippy(tooltipSelector, options);
        };

        return {
            init
        };

    })();

    tooltip.init();

})();