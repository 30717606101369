(function() {

    "use strict";

    const timer = (() => {

        let timerContainer = $('.quiz-timer p'),
            formTimer = document.getElementById('stage_first_form_time') || document.getElementById('stage_second_form_time');

        let elapsedSeconds = 0;

        const getTimeString = (num) => {
            return (num < 10 ? "0" : "") + num;
        };

        const getTimer = (totalSeconds) => {
            let hours = Math.floor(totalSeconds / 3600);
            totalSeconds = totalSeconds % 3600;

            let minutes = Math.floor(totalSeconds / 60);
            totalSeconds = totalSeconds % 60;

            let seconds = Math.floor(totalSeconds);

            hours = getTimeString(hours);
            minutes = getTimeString(minutes);
            seconds = getTimeString(seconds);

            let currentTimeString = hours + ":" + minutes + ":" + seconds;

            formTimer.value = currentTimeString;
            return currentTimeString;
        };

        const init = () => {
            if (timerContainer.length) {
                setInterval(function() {
                    elapsedSeconds = elapsedSeconds + 1;
                    timerContainer.text(getTimer(elapsedSeconds));
                }, 1000);
            }
        };

        return {
            init
        };

    })();

    timer.init();

})();