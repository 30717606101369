(function() {

    "use strict";

    const quiz = (() => {

        const $scrollContainer = $(".nano"),
            $quizSection = $('.section-quiz'),
            $questions = $quizSection.find('.quiz-item'),
            $submitBtn = $quizSection.find('.btn'),
            scrollOptions = {
                alwaysVisible: true
            },
            stepLength = 6,
            scrollEnabledMax = 1024,

            quizNav = {
                next: $('.quiz-nav-next')
            };

        let currentStep = 0,
            totalSteps = 0;

        const nextStep = () => {
            currentStep++;
            showStep();
            $("html, body").animate({
                scrollTop: $quizSection.offset().top
            }, 300);
        };

        const getTotalSteps = () => {
            totalSteps = Math.ceil($questions.length / stepLength);
        };

        const showStep = () => {
            let startIndex = (currentStep * stepLength),
                stopIndex = startIndex + stepLength,
                $questionsToShow = $questions.slice(startIndex, stopIndex);

            $questions.hide();
            $questionsToShow.show();

            if (currentStep === totalSteps - 1) {
                quizNav.next.hide();
                $submitBtn.show();
            }
        };

        const onResize = () => {
            window.addEventListener('resize', () => {
                if (window.innerWidth > scrollEnabledMax) {
                    $scrollContainer.nanoScroller({
                        destroy: true
                    });
                } else {
                    $scrollContainer.nanoScroller(scrollOptions);
                }
            });
        };

        const addEvents = () => {
            onResize();
            quizNav.next.on('click', () => nextStep());
        };

        const init = () => {
            if ($quizSection.length && $questions.length) {
                addEvents();
                getTotalSteps();
                showStep();
                $scrollContainer.nanoScroller(scrollOptions);
            }
        };

        return {
            init
        };

    })();

    quiz.init();

})();