(function() {

    const messages = (() => {

        const messageContainer = document.querySelector(".flash");

        const messageClose = () => {
            $(messageContainer).slideUp(200);
        };

        const init = () => {
            if (messageContainer) {
                const messageCloseBtn = messageContainer.querySelector('.close');
                messageCloseBtn.addEventListener('click', messageClose);
            }
        };

        return {
            init
        };

    })();

    messages.init();

})();