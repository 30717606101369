// Validation errors messages for Parsley
// Load this after Parsley

Parsley.addMessages('pl', {
    defaultMessage: "Wartość wygląda na nieprawidłową",
    type: {
        email: "Wpisz poprawny adres e-mail.",
        url: "Wpisz poprawny adres URL.",
        number: "Wpisz poprawną liczbę.",
        integer: "Dozwolone są jedynie liczby całkowite.",
        digits: "Dozwolone są jedynie cyfry.",
        alphanum: "Dozwolone są jedynie znaki alfanumeryczne."
    },
    notblank: "Pole nie może być puste.",
    required: "Pole jest wymagane.",
    pattern: "Pole zawiera nieprawidłową wartość.",
    min: "Wartość nie może być mniejsza od %s.",
    max: "Wartość nie może być większa od %s.",
    range: "Wartość powinna zaweriać się pomiędzy %s a %s.",
    minlength: "Minimalna ilość znaków wynosi %s.",
    maxlength: "Maksymalna ilość znaków wynosi %s.",
    length: "Ilość znaków wynosi od %s do %s.",
    mincheck: "Wybierz minimalnie %s opcji.",
    maxcheck: "Wybierz maksymalnie %s opcji.",
    check: "Wybierz od %s do %s opcji.",
    equalto: "Wartości nie są identyczne."
});

Parsley.setLocale('pl');


(function() {

    const formValidation = (() => {

        const options = {
            errorClass: 'form-error'
        };

        const $formRegulations = $('.form-regulations');

        const validateRegulations = () => {
            if ($formRegulations.length) {
                $formRegulations.parsley(options);
            }
        };

        const addEvents = () => {
            validateRegulations();
        };

        const init = () => {
            addEvents();
        };

        return {
            init
        };

    })();

    const loginValidation = (() => {

        const $loginForm = $('#login-form'),
            loginData = {};
        let loginInput, passwordInput;

        const validateInput = () => {
            $.ajax({
                    type: 'POST',
                    url: "/login",
                    data: loginData,
                    dataType: "json",
                })
                .always((responseText) => {
                    validate(responseText);
                });
        };

        const setMessage = (options) => {
            if (options && options.element) {
                let errorList = options.element.parent('.form-row').next('.parsley-errors-list');

                if (!errorList.length) {
                    options.element.parent('.form-row').after('<ul class="parsley-errors-list"><li>' + options.message + '</li></ul>')
                } else {
                    errorList.find('li').text(options.message);
                }

                options.element.addClass('error');
            }
        };

        const validate = (statusCode) => {
            if (statusCode === 200) {
                window.location.reload();
            } else if (statusCode === 404) { // HTTP_NOT_FOUND user not found
                setMessage({
                    message: 'Nieprawidłowa nazwa użytkownika',
                    element: loginInput
                });
            } else if (statusCode === 409) { // HTTP_CONFLICT password invalid
                setMessage({
                    message: 'Nieprawidłowe hasło',
                    element: passwordInput
                });
            }
        };

        const getData = () => {
            loginInput = $loginForm.find('#login');
            passwordInput = $loginForm.find('#password');

            clearErrorMessages(loginInput);
            clearErrorMessages(passwordInput);

            loginData["_username"] = (loginInput.val()).trim();
            loginData["_password"] = (passwordInput.val()).trim();
        };

        const clearErrorMessages = ($input) => {
            $input.on('keyup', (e) => {
                $(e.target).removeClass('error');
                $(e.target).parent('.form-row').next('.parsley-errors-list').find('li').text('');
            });
        };

        const init = () => {
            if ($loginForm.length) {
                $loginForm.on('submit', (e) => {
                    e.preventDefault();
                    getData();
                    validateInput();
                });
            }

        };

        return {
            init
        };

    })();

    const buttons = (() => {

        const btnSelector = '.btn',
            disabledClass = 'btn-disabled';

        const addEvents = () => {
            let $btn = $(btnSelector);
            $btn.on('click', (e) => {
                if ($(e.target).hasClass(disabledClass)) {
                    e.preventDefault();
                }
            });
        };

        const init = () => {
            addEvents();
        };

        return {
            init
        }

    })();

    formValidation.init();
    loginValidation.init();
    buttons.init();

})();