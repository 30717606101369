(function() {

    "use strict";

    const menu = (() => {

        const button = document.querySelector('.nav-btn'),
            body = document.body,
            menuSelector = '.main-menu',
            openMenuClass = 'menu-open',
            menu = body.querySelector(menuSelector),
            menuScrolledClass = 'menu-scrolled',
            dropdown = menu.querySelector('.dropdown'),
            dropdownCheckbox = menu.querySelector('.menu-dropdown'),
            dropdownOpenClass = 'dropdown-open',

            $dropdownToggle = $('.news-toggle'),
            $mainMenuNewslist = $('.news-dropdown'),

            desktopMin = 1024,
            scrollOffset = 200;

        const openMenu = () => body.classList.add(openMenuClass);
        const closeMenu = () => body.classList.remove(openMenuClass);
        const getMenuState = () => body.classList.contains(openMenuClass);
        const toggleMenu = () => body.classList.contains(openMenuClass) ? closeMenu() : openMenu();

        const scrollMenu = () => {
            window.addEventListener('scroll', function() {
                let disableSubmenus = window.pageYOffset > scrollOffset;
                return disableSubmenus ? body.classList.add(menuScrolledClass) : body.classList.remove(menuScrolledClass);
            });
        };

        const dropdownToggle = () => {
            if (dropdownCheckbox) {
                dropdownCheckbox.addEventListener('change', (e) => {
                    return e.target.checked ? dropdown.classList.add(dropdownOpenClass) : dropdown.classList.remove(dropdownOpenClass);
                });
            }
        };

        const dropdownNewsToggle = () => {
            $dropdownToggle.on('click', (e) => {
                if (window.innerWidth < desktopMin) {
                    e.preventDefault();
                    $mainMenuNewslist.find('.news-list').slideToggle(200, () => {
                        if ($mainMenuNewslist.find('.news-list').is(':visible')) {
                            $mainMenuNewslist.addClass(dropdownOpenClass);
                        } else {
                            $mainMenuNewslist.removeClass(dropdownOpenClass);
                        }
                    });
                }
            });
        };

        const addEvents = () => {
            button.addEventListener('click', toggleMenu);
            scrollMenu();
            dropdownToggle();
            dropdownNewsToggle();
        };

        const init = () => addEvents();

        return {
            init,
            openMenu,
            closeMenu,
            getMenuState
        };

    })();

    menu.init();

})();