(function() {

    "use strict";

    const presentation = (() => {

        let $presentation = $('.presentation'),
            $presentationSlideCount = $('.presentation-slide-count'),
            $presentationProgressBar = $('.progress-bar-active'),
            $progressText = $('.progress-text span'),
            $presentationFinished = $('.presentation-finished'),
            nextIcon = `<svg class="icon icon-arrow icon-arrow-right"><use xlink:href="#ico-arrow-right"></use></svg>`,
            prevIcon = `<svg class="icon icon-arrow icon-arrow-left"><use xlink:href="#ico-arrow-left"></use></svg>`,
            totalSlides = 0,
            currentSlide = 0,
            progressSaved = false,

            options = {
                items: 1,
                lazyLoad: true,
                loop: false,
                pullDrag: false,
                autoHeight: false,
                navContainer: '.presentation-nav',
                nav: true,
                navText: [prevIcon, nextIcon],
                onInitialized: (e) => {
                    getSlidesTotal(e);
                    updateProgressBar(e);
                },
                onTranslated: (e) => {
                    updateCurrentSlide(e);
                    updateProgressBar(e);
                    $presentationFinished.css('height', e.target.offsetHeight);
                    if (!progressSaved && totalSlides === currentSlide) {
                        registerProgress();
                    }
                },
                onResize: (e) => {
                    $presentationFinished.css('height', 'auto');
                    setTimeout(() => {
                        $presentationFinished.css('height', e.target.offsetHeight);
                    }, 500);
                }
            };

        const registerProgress = () => {
            $.ajax({
                type: 'POST',
                url: "/etapII/video",
                data: {},
                dataType: "json",
                statusCode: {
                    200: () => {
                        progressSaved = true;
                    },
                    404: () => {
                        progressSaved = true;
                    }
                }
            });
        };

        const getSlidesTotal = (e) => {
            totalSlides = e.item.count;
            $presentationSlideCount.find('.total').text(totalSlides);
        };

        const updateCurrentSlide = (e) => {
            currentSlide = e.item.index + 1;
            $presentationSlideCount.find('.current').text(currentSlide);
        };

        const updateProgressBar = (e) => {
            currentSlide = e.item.index + 1;
            let currentProgress = parseInt((currentSlide / totalSlides) * 100);
            $presentationProgressBar.css('width', currentProgress + '%');
            $progressText.text(currentProgress);
        };

        const init = () => {
            if ($presentation.length) {
                $presentation.owlCarousel(options);
            }
        };

        return {
            init
        };

    })();

    presentation.init();

})();
