(function() {

    "use strict";

    const scroll = (() => {

        const $scrollBtn = $('.scroll-btn'),
            $scrollSection = $scrollBtn.closest('section'),
            scrollSpeed = 500;

        const addEvents = () => {

            if ($scrollSection.length) {
                let $scrollTarget = $scrollSection.next();
                $scrollBtn.on('click', (e) => {
                    e.preventDefault();
                    $("html, body").animate({
                        scrollTop: $scrollTarget.offset().top - 60
                    }, scrollSpeed);
                });
            }

        };

        const init = () => {
            if ($scrollBtn) {
                addEvents();
            }
        };

        return {
            init
        };

    })();

    scroll.init();

})();