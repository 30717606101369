(function() {

    "use strict";

    const scrollbar = (() => {

        const scrollContainer = $(".nano"),
            options = {
                alwaysVisible: true
            };

        const init = () => {
            scrollContainer.nanoScroller(options);
        };

        return {
            init
        };

    })();

    scrollbar.init();

})();