(function() {

    "use strict";

    const app = {};

    app.main = (() => {

        const init = () => {

        };

        return {
            init
        };

    })();

})();