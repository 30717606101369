(function() {

    "use strict";

    const videoPlayer = (() => {

        const videoContainer = document.querySelector('.col-video');

        if (videoContainer) {
            var video = videoContainer.querySelector('video'),
                videoPlayingClass = 'video-playing',
                nextVideoIntroClass = 'show-next-video-intro',
                videosCompleteClass = 'videos-complete',
                nextLevelBtn = document.querySelector('.btn-next-level'),
                progressContainer = document.querySelector('.video-progress'),
                progressBar = document.querySelector('.progress-bar-active'),

                nextVideoIntro = videoContainer.querySelector('.next-video-intro'),
                nextVideoBtn = nextVideoIntro.querySelector('.btn-next-video'),
                btnDisabledClass = 'btn-disabled',
                allVideosPlayed = false,
                timeStarted = 0,
                timePlayed = 0,
                progressInterval;

            var controls = {
                    play: videoContainer.querySelector('.video-play'),
                    pause: videoContainer.querySelector('.video-pause'),
                    fullScreen: videoContainer.querySelector('.video-fullscreen'),
                    replay: videoContainer.querySelector('.video-replay')
                },

                state = {
                    progress: 0,
                    duration: video.duration
                };
        }

        const launchFullscreen = (element) => {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        };

        const exitFullScreen = () => {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        };

        const scrollToPageTop = () => {
            $("html, body").animate({
                scrollTop: 0
            }, 300);
        };

        const closeMessage = () => {
            $('.flash').on('click', '.close', () => {
                $('.flash').slideUp(200);
            });
        };

        const setTimeStarted = () => {
            $('video').one('play', () => timeStarted = new Date());
        };

        const addMessage = () => {
            let message = 'Aby przejść do testu musisz obejrzeć wykład w całości.',
                $messageHTML = `<div class="flash error"><div class="inner container">${message}<a href="#" class="close">&times;</a></div></div>`,
                $flash = $('.flash');

            if ($flash.length) {
                $flash.find('.container').innerText = message;
                $flash.show();
            } else {
                $($messageHTML).insertAfter('.main-header');
            }

            scrollToPageTop();
            closeMessage();
            setTimeStarted();
        };

        const sendData = (callback) => {
            $.ajax({
                type: 'POST',
                url: "/etapI/video",
                data: {
                    time: timePlayed,
                    duration: formatDate(state.duration)
                },
                dataType: "json",
                statusCode: {
                    200: () => {
                        callback();
                    },
                    409: () => {
                        addMessage();
                    }
                }
            });
        };

        const getTimeString = (num) => {
            return (num < 10 ? "0" : "") + num;
        };

        const formatDate = (totalSeconds) => {
            let hours = Math.round(totalSeconds / 3600);
            totalSeconds = totalSeconds % 3600;

            let minutes = Math.round(totalSeconds / 60);
            totalSeconds = totalSeconds % 60;

            let seconds = Math.round(totalSeconds);

            hours = getTimeString(hours);
            minutes = getTimeString(minutes);
            seconds = getTimeString(seconds);

            return hours + ":" + minutes + ":" + seconds;
        };

        const hideReplay = () => {
            videoContainer.classList.remove(videosCompleteClass);
        };

        const setVideoStop = () => {
            videoContainer.classList.remove(videoPlayingClass);
            video.pause();
        };

        const setVideoPlay = () => {
            videoContainer.classList.add(videoPlayingClass);
            video.play();
            hideReplay();
        };

        const hideIntro = () => {
            videoContainer.classList.remove(nextVideoIntroClass);
            allVideosPlayed = true;
            setVideoPlay();
        };

        const showReplay = () => {
            videoContainer.classList.add(videosCompleteClass);
        };

        const videosFinished = () => {
            showReplay();
            progressBar.style.width = '100%';
            let nextLevelBlocked = $('.btn-next-level').hasClass(btnDisabledClass);
            if (nextLevelBlocked) {
                sendData(enableNextLevel);
            }
        };

        const videoEndedCallback = () => {
            video.addEventListener('ended', () => {
                exitFullScreen();
                video.currentTime = 0;
                var timeEnded = new Date();
                timePlayed = formatDate(((timeEnded - timeStarted) / 1000) + 3);
                videosFinished();
            });
        };

        const enableNextLevel = () => {
            exitFullScreen();
            nextLevelBtn.classList.remove(btnDisabledClass);
            $('.flash.error').slideUp(200);
        };

        const getVideoProgress = () => {
            clearInterval(progressInterval);
            progressInterval = setInterval(function() {
                state.current = video.currentTime;
                updateProgressBar();
            }, 150);
        };

        const updateProgressBar = (progress) => {
            let width = progress || (state.current / state.duration) * 100;
            progressBar.style.width = width + '%';
        };

        const progressClick = () => {
            progressContainer.addEventListener('click', (e) => {
                let progressDimensions = progressContainer.getBoundingClientRect(),
                    clickPos = e.pageX - progressDimensions.left,
                    percentage = (clickPos / progressDimensions.width) * 100,
                    videoNewCurrent = (state.duration * percentage) / 100;

                if (clickPos && percentage) {
                    updateProgressBar(percentage);
                    video.currentTime = videoNewCurrent;
                }
            });
        };

        const addEvents = () => {

            controls.play.addEventListener('click', () => setVideoPlay());
            controls.pause.addEventListener('click', () => setVideoStop());
            controls.fullScreen.addEventListener('click', () => launchFullscreen(video));
            controls.replay.addEventListener('click', () => setVideoPlay());

            nextVideoBtn.addEventListener('click', hideIntro);

            video.addEventListener('durationchange', (e) => state.duration = e.target.duration);
            video.addEventListener('pause', () => clearInterval(progressInterval));
            video.addEventListener('playing', getVideoProgress);

            setTimeStarted();
            videoEndedCallback();
            progressClick();

        };

        const init = () => {
            if (videoContainer && controls.play) {
                addEvents();
            }
        };

        return {
            init
        };

    })();

    videoPlayer.init();

})();