(function() {

    const modal = (() => {

        const options = {
            removalDelay: 300,
            mainClass: 'mfp-fade'
        };

        const addEvents = () => {
            $('.modal-open').magnificPopup(options);
        };

        const init = () => {
            addEvents();
        };

        return {
            init
        };

    })();

    modal.init();

})();
